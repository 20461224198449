import React from 'react';

import Home from './components/Home';

import {BrowserRouter, Routes, Route} from "react-router-dom";
import './style/App.scss';
import Kontakt from './components/Kontakt';
import Ueberuns from './components/Ueberuns';
import Referenzen from './components/Referenzen';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/ueber-uns" element={<Ueberuns/>}/>
        <Route path="/referenzen" element={<Referenzen/>}/>
        <Route path="/kontakt" element={<Kontakt/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
