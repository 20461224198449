import React from 'react';
import NavBarLogo from './NavBarLogo';
import NavBarItem from './NavBarItem';
import { FaBars } from 'react-icons/fa';
import '../../style/NavBar.scss';
import { useNavigate } from "react-router-dom";

function NavBar() {
    const navigate = useNavigate();

    function openMenu() {
        document.getElementById('menu').style.visibility = 'visible';
        document.getElementById('fabars').style.visibility = 'hidden';
        document.getElementById('fabars2').style.visibility = 'hidden';
    }

    function backToHome() {
        navigate("/");
    }

    return (
        <div className='navbar'>
            <div className='navbar-logo-container'  onClick={backToHome}>
                <NavBarLogo title={"clownpaar"}/>
            </div>
            <div className='navbar-list'>
                <NavBarItem name='Home' link='/'></NavBarItem>
                <NavBarItem name='Über uns' link='/ueber-uns'></NavBarItem>
                <NavBarItem name='Referenzen' link='/referenzen'></NavBarItem>
                <NavBarItem name='Kontakt' link='/kontakt'></NavBarItem>
            </div>
            <div id='fabars' className='navbar-fabars-button' onClick={openMenu}>
                <FaBars id='fabars2' className='navbar-fabars' size={'4vh'}/>
            </div>
        </div>
    );
}

export default NavBar;