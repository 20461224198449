import '../../style/NavBar.scss';
import logo from '../../assets/clownpaar-logo-2.png';

function NavBarLogo(props) {
    return (
        <div className='navbar-logo'>
            <img className='navbar-logo-img' src={logo} alt=''></img>
        </div>
    );
}

export default NavBarLogo;