import React from 'react';
import NavBar from './navbar/NavBar';
import { IoMdClose } from 'react-icons/io';
import '../style/Home.scss';
import { useNavigate } from "react-router-dom";
import couple from '../assets/couple.jpg';
import flower from '../assets/1.svg';
import besuchsdienst from '../assets/besuchsdienst.jpg';
import bühnenprogramm from '../assets/bühnenprogramm.jpg';
import clowngeschichte from '../assets/clowngeschichte.jpg';

function Home() {
    const navigate = useNavigate();

    function closeMenu() {
        document.getElementById('menu').style.visibility = 'hidden';
        document.getElementById('fabars').style.visibility = 'visible';
        document.getElementById('fabars2').style.visibility = 'visible';
    }

    function navigate1() {
        navigate("/ueber-uns");
    }

    function navigate2() {
        navigate("/referenzen");
    }

    function navigate3() {
        navigate("/kontakt");
    }

    return (
        <div className='App'>
            <div id='menu' className='menu'>
                <div className='menu-esc-container'>
                    <div className='menu-esc' onClick={closeMenu}>
                        <IoMdClose size={'6vh'}/>
                    </div>
                </div>
                <div className='menu-items-container'>
                    <div className='menu-item' onClick={navigate1} >Über uns</div>
                    <div className='menu-item' onClick={navigate2} >Referenzen</div>
                    <div className='menu-item' onClick={navigate3} >Kontakt</div>
                </div>
            </div>
            <NavBar />
            <div className='header'>
                <img className='image' src={couple} alt=''></img>
            </div>
            <div className='body'>
                <div className='text-block'>
                    Freue dich an den kleinen Dingen im Leben, denn sie machen das Leben grossartig!
                    <div className='small-img'>
                        <img className='image' src={flower} alt=''></img>
                    </div>
                </div>
                <div className='title-block'>
                    Freude schenken
                </div>
                <div className='categories-block'>
                    <div className='category'>
                        <div className='category-title'>
                            Unterhaltung
                        </div>
                        <div className='category-img'>
                            <img className='image' src={bühnenprogramm} alt=''></img>
                            <div className='category-text'>
                            Geburtstage, Sommerfeste, Hochzeiten, Eröffnungen – gerne mischen wir uns unter die Leute oder bieten ein 15-20 minütiges Clowntheater an.<br></br><br></br>
                            Unser Clowntheater ist für Gross und Klein sehr spannend. Wir sprechen das sogenannte «Gromolo». Das tolle daran – jeder versteht es! Dazu natürlich eine grosse Portion an Mimik und Gestik. Und damit ein optimales Theaterfeeling entsteht, bringen wir einen Vorhang mit.<br></br><br></br>
                            So könnten 90 Minuten aussehen: Wir machen ein 20-minütiges Clowntheater, 40 Minuten mischen wir uns unter die Leute und 30 Minuten formen wir Ballone. (Ballone formen wir nur in Kombination von Clowntheater und/oder unter die Leute mischen).<br></br><br></br>
                            Interessiert? Dann melde dich via Kontakt bei uns, gerne besprechen wir mit dir, eine passende Unterhaltung.
                            </div>
                        </div>
                    </div>
                    <div className='category'>
                        <div className='category-title'>
                            Clown-Geschichte
                        </div>
                        <div className='category-img'>
                            <img className='image' src={clowngeschichte} alt=''></img>
                            <div className='category-text'>
                            Wir haben ein 40-minütiges Bühnenprogramm für die Weihnachtszeit. Es heisst: »Beflügelte Pop-Corns».<br></br><br></br>
                            Dies ist ein Clowntheater mit selbst komponierter Musik und Liedern, passend zur Weihnachtszeit. Warum kam Jesus an Weihnachten auf die Welt? Schubi-Dubi und Ambrella wollen das herausfinden und stossen dabei immer wieder auf Pop-Corns. Ob die Pop-Corns ihnen helfen können?<br></br><br></br>
                            "Eine Show für die ganze Familie. Auf klare und kinderfreundliche Weise dargebracht. Hoch amüsant, humorvoll und mit ganz viel Gefühl. Damaris und Lukas, ihr schafft es jedesmal uns mit euren Stücken mitsamt den tollen Liedern auf eine Reise mitzunehmen – echt beeindruckend. Ihr vermittelt ganz viel Liebe, Freude, Lockerheit. Wir freuen uns schon auf eure nächste Show."<br></br>Marilena<br></br><br></br>
                            Musik und Text: Damaris Steiner<br></br>
                            Inszenierung: Damaris und Lukas Steiner<br></br>
                            Interessiert? Dann melde dich via Kontakt bei uns!
                            </div>
                        </div>
                    </div>
                    <div className='category'>
                        <div className='category-title'>
                            Besuchsdienst
                        </div>
                        <div className='category-img'>
                            <img className='image' src={besuchsdienst} alt=''></img>
                            <div className='category-text'>
                            Wir glauben, dass es Clowns in Pflegeinstitutionen braucht. Als Clown haben wir die Möglichkeit die Bewohner anders abzuholen als das Pflegepersonal, Angehörige oder Besucher. Die Bewohner können mit uns eine unbeschwerte Zeit erleben. Wir können mit ihnen in eine Welt eintauchen, die zum Träumen führt oder anregt. Die Bewohner haben die Möglichkeit von ihren Sorgen zu erzählen oder von früher, was sie schon alles erlebt haben. Das Singen vertrauter Lieder weckt schöne Erinnerungen und fördert die Wahrnehmung. Oft und gerne improvisieren wir ein ganz persönliches Lied. So entstand schon ein Fitness-Lied, Zimmer-Lied, Katzen-Lied und viele andere. Wir singen auch bekannte Lieder wie z.B. «Wir sind mit dem Velo da».<br></br><br></br>
                            Durch unsere Anwesenheit zieht Abwechslung und Fröhlichkeit ein. Die Bewohner haben Gesellschaft. Stress und Anspannung werden reduziert und auch Angst kann genommen werden. Schmerzen werden weniger wahrgenommen.<br></br><br></br>
                            Wir begegnen mit Respekt, Achtsamkeit und voller Präsenz.<br></br><br></br>
                            Wir machen gerne persönliche Besuche oder ein Clowntheater für alle im Aufenthaltsraum.<br></br><br></br>
                            Interessiert? Dann melde dich via Kontakt bei uns, gemeinsam besprechen wir einen passenden Besuch.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer'>
                Copyright {new Date().getFullYear()} © Damaris & Lukas Steiner | Homepage & Portrait-Fotos realisiert durch Davide Fontanella
            </div>
        </div>
    );
}

export default Home;