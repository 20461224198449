import React from 'react';
import NavBar from './navbar/NavBar';
import { IoMdClose } from 'react-icons/io';
import '../style/Home.scss';
import '../style/Kontakt.scss';
import { useNavigate } from "react-router-dom";
import kontakt from '../assets/kontakt.jpg';
import portrait1 from '../assets/portrait1.jpg';
import portrait2 from '../assets/portrait2.jpg';

function Kontakt() {
    const navigate = useNavigate();

    function closeMenu() {
        document.getElementById('menu').style.visibility = 'hidden';
        document.getElementById('fabars').style.visibility = 'visible';
        document.getElementById('fabars2').style.visibility = 'visible';
    }

    function navigate0() {
        navigate("/");
    }

    function navigate1() {
        navigate("/ueber-uns");
    }

    function navigate2() {
        navigate("/referenzen");
    }

    function navigate3() {
        navigate("/kontakt");
    }

    return (
        <div className='App'>
            <div id='menu' className='menu'>
                <div className='menu-esc-container'>
                    <div className='menu-esc' onClick={closeMenu}>
                        <IoMdClose size={'6vh'}/>
                    </div>
                </div>
                <div className='menu-items-container'>
                    <div className='menu-item' onClick={navigate0} >Home</div>
                    <div className='menu-item' onClick={navigate1} >Über uns</div>
                    <div className='menu-item' onClick={navigate2} >Referenzen</div>
                    <div className='menu-item' onClick={navigate3} >Kontakt</div>
                </div>
            </div>
            <NavBar />
            <div className='header'>
                <img className='image' src={kontakt} alt=''></img>
            </div>
            <div className='body'>
                <div className='title-block'>
                    Kontakt
                </div>
                <div className='kontakt-container'>
                    <div className='kontakt'>
                        <div className='kontakt-img'>
                            <img className='image' src={portrait1} alt=''></img>
                        </div>
                        <div className='kontakt-daten'>
                            „Ambrella und Schubi-Dubi“<br></br>
                            Damaris und Lukas Steiner<br></br>
                            clownpaar@livenet.ch
                        </div>
                        <div className='kontakt-img'>
                            <img className='image' src={portrait2} alt=''></img>
                        </div>
                    </div>
                </div>
                <div className='kontakt-text'>
                    Du möchtest informiert werden über unsere nächsten öffentlichen Auftritte?<br></br>Gerne senden wir dir unsere Clownpaar-News zu, kontaktiere uns via E-mail.
                </div>
            </div>
            <div className='footer'>
                Copyright {new Date().getFullYear()} © Damaris & Lukas Steiner | Homepage & Portrait-Fotos realisiert durch Davide Fontanella
            </div>
        </div>
    );
}

export default Kontakt;