import React from 'react';
import NavBar from './navbar/NavBar';
import { IoMdClose } from 'react-icons/io';
import '../style/Referenzen.scss';
import { useNavigate } from "react-router-dom";
import referenzen from '../assets/referenzen.jpg';

function Referenzen() {
    const navigate = useNavigate();

    function closeMenu() {
        document.getElementById('menu').style.visibility = 'hidden';
        document.getElementById('fabars').style.visibility = 'visible';
        document.getElementById('fabars2').style.visibility = 'visible';
    }

    function navigate0() {
        navigate("/");
    }

    function navigate1() {
        navigate("/ueber-uns");
    }

    function navigate2() {
        navigate("/referenzen");
    }

    function navigate3() {
        navigate("/kontakt");
    }

    return (
        <div className='App'>
            <div id='menu' className='menu'>
                <div className='menu-esc-container'>
                    <div className='menu-esc' onClick={closeMenu}>
                        <IoMdClose size={'6vh'}/>
                    </div>
                </div>
                <div className='menu-items-container'>
                    <div className='menu-item' onClick={navigate0} >Home</div>
                    <div className='menu-item' onClick={navigate1} >Über uns</div>
                    <div className='menu-item' onClick={navigate2} >Referenzen</div>
                    <div className='menu-item' onClick={navigate3} >Kontakt</div>
                </div>
            </div>
            <NavBar />
            <div className='header'>
                <img className='image' src={referenzen} alt=''></img>
            </div>
            <div className='body'>
                <div className='title-block'>
                    Referenzen
                </div>
                <div className='referenzen-container'>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Die Kindergärtner wurden von der ersten Sekunde zum mitfiebern und lachen abgeholt. Grosse Augen und Kinderstrahlen bis zum Schluss. Einfach wunderbar! Gerne immer wieder! Man spürt die grosse Freude der beiden Clowns die ansteckt 😊"
                        </div>
                        <div className='referenzen-item-author'>
                            Bianca, Kindergartenlehrerin
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Liebe Damaris, Lieber Lukas, Anlässlich meines 60. Geburtstags hattet ihr ein wunderschönes Clowntheater präsentiert. Alle 60 geladene Gäste von Jung bis Alt waren begeistert. Ich habe eure selbstinszenierte Darbietung zum Geburtstag sehr geschätzt, es war grossartig wir ihr die Gäste mit eurer Show begeistert habt, mit Humor und Tiefgang. Ein unvergesslicher schöner Abend auch dank euch!"
                        </div>
                        <div className='referenzen-item-author'>
                            Urs, Geburstagsfeier
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Ihr wart witzig, sehr humorvoll, spannend, geheimnisvoll, ruhig, für jedes Publikum interessant, einfühlsam, spontan und einfach WUNDERBAR. Wir engagieren euch sehr gerne wieder und empfehlen euch weiter. Nochmals herzlichen Dank!"
                        </div>
                        <div className='referenzen-item-author'>
                            Sabine, Tom und Alisha, Insieme21 Frühlingsfest
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Meine Familie und Freunde haben die kreativen, lustigen und erfrischenden Inszenierungen von Ambrella und Schubi-Dubi sehr genossen. Es ist unwahrscheinlich, wie viele Emotionen man ohne Worte ausdrücken kann. Ich wünsche Euch weiterhin viel Erfolg und Spass auf der Bühne!"
                        </div>
                        <div className='referenzen-item-author'>
                            Bernadette Rüttimann, Geburtstagsfeier
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Als Schubi-Dubi und Ambrella habt ihr dem Frauenfrühstück eine wunderbare, fröhliche und unbeschwerte Note gegeben! Ihr habt uns zum Lachen, aber besonders mit dem Himmelsfenster, auch zum Staunen und Nachdenken gebracht! Nochmals ganz herzlichen Dank für euern Auftritt!!!!"
                        </div>
                        <div className='referenzen-item-author'>
                            Emma, Frauenfrühstück
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Das Theater war sehr lustig und obwohl die beiden nur selten gesprochen haben, habe ich alles verstanden."
                        </div>
                        <div className='referenzen-item-author'>
                            3. Klasse, Weihnachtsaufführung Beflügelte Pop-Corns
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Die Clowns haben immer wieder lustige Ideen. Zum Bespiel einen Autotank mit Popel zu füllen."
                        </div>
                        <div className='referenzen-item-author'>
                            3. Klasse, Weihnachtsaufführung Beflügelte Pop-Corns
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Ich habe die Lieder sehr schön gefunden und die Instrumente dazu waren lustig. Das Lied mit den Popcorns kann ich nun fast auswendig."
                        </div>
                        <div className='referenzen-item-author'>
                            3. Klasse, Weihnachtsaufführung Beflügelte Pop-Corns
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Clowns mit groooossen Augen und viel Schalk ziehen die Kids in ihren Bann – mit ausdrucksvoller Mimik, Gestik, Wortfetzen und Gesang. Popcornduft liegt in der Luft. Kein Wunder, stürmen die jungen Zuschauer am Ende die Bühne und putzen auch die letzte weisse «Flocke» weg…"
                        </div>
                        <div className='referenzen-item-author'>
                            Zuschauer, Weihnachtsaufführung, Beflügelte Popcorns
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Ich finde euch cool und lustig. Vor allem finde ich es witzig wenn Schubi-Dubi stolpert. Und besonders schön ist es wenn Ambrella mit der Guitalele spielt."
                        </div>
                        <div className='referenzen-item-author'>
                            Joana, 8 Jahre
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Das war wunderbar! Mir hat es sehr gefallen!"
                        </div>
                        <div className='referenzen-item-author'>
                            Dorli, Altersheimbewohnerin
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Es war sehr toll, vor allem dass sie nicht immer gleicher Meinung waren."
                        </div>
                        <div className='referenzen-item-author'>
                            Samuel, 7 Jahre
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Der Bühnenauftritt hat mir sehr gefallen, die Mischung von gesprochenem (Gromolo) und Musik war super."
                        </div>
                        <div className='referenzen-item-author'>
                            Martin, Geburstagsfest
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Die Weihnachtsgeschichte aus der Sicht von Schubi-Dubi erzählt und geschrieben von Damaris. Das war für gross und klein spannend, lustig, unterhaltsam und lehrreich! Sehr ausdrucksstark. Auch ohne ein Wort von Schubi-Dubi war für alle klar, welche Gefühle er hat."
                        </div>
                        <div className='referenzen-item-author'>
                            Aurelia, Religionslehrerin
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Schubi-Dubi und Ambrella haben eine wunderbare Fröhlichkeit verbreitet. Ich konnte herzhaft lachen und den professionellen Auftritt geniessen."
                        </div>
                        <div className='referenzen-item-author'>
                            Ursula, Spialplatzeröffnung CZZ
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Schubi-Dubi und Ambrella leben ihren Clown mit sehr viel Herz und berühren jedes Kinder und Erwachsenen Herz! Ich wünsche mir noch viele solche Anlässe."
                        </div>
                        <div className='referenzen-item-author'>
                            Natalie, Kinderanlass im Waschhüsli
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Ambrella hat die Kinder ab der ersten Minute in ihren Bann gezogen. Es war schön zu sehen, wie sich die Kinder freuten und wie neugierig und gespannt sie darauf warteten um zu sehen was als nächstes passiert. Es war eine schöne und lustige Erfahrung Ambrella in unserer Spielgruppe zu haben. Danke, dass du den Kindern ein lachen in ihr Gesicht gezaubert hast."
                        </div>
                        <div className='referenzen-item-author'>
                            Jessica, Spielgruppenleiterin (Besuch von Ambrella)
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Die Kinder waren so fasziniert, dass sie immer näher an die Bühne rückten! Trotz der Grösse der Veranstaltung wurde eine besondere Nähe zu den Kindern geschafft.!"
                        </div>
                        <div className='referenzen-item-author'>
                            Jan, Kreatag
                        </div>
                    </div>
                    <div className='referenzen-item'>
                        <div className='referenzen-item-text'>
                            "Ambrella und Schubi-Dubi bringen Kinderaugen zum Strahlen und unaufhörliches Gelächter hallt durch das Schulzimmer. Noch lange nach dem Auftritt schwärmten die Kinder von den beiden lustigen Clowns."
                        </div>
                        <div className='referenzen-item-author'>
                            Tabea, Klassenlehrerin
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer'>
                Copyright {new Date().getFullYear()} © Damaris & Lukas Steiner | Homepage & Portrait-Fotos realisiert durch Davide Fontanella
            </div>
        </div>
    );
}

export default Referenzen;