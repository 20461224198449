import '../../style/NavBar.scss';
import { useNavigate } from "react-router-dom";

function NavBarItem(props) {
    const navigate = useNavigate();

    function followLink() {
        navigate(props.link);
    }

    return (
        <div className='navbar-item' onClick={followLink}>
            <h1>{props.name}</h1>
        </div>
    );
}

export default NavBarItem;