import React from 'react';
import NavBar from './navbar/NavBar';
import { IoMdClose } from 'react-icons/io';
import '../style/Ueberuns.scss';
import überuns from '../assets/überuns.jpg';
import bild1 from '../assets/bild1.jpg';
import bild2 from '../assets/bild2.jpg';
import bild3 from '../assets/bild3.jpg';
import bild4 from '../assets/bild4.jpg';
import bild6 from '../assets/bild6.jpg';
import bild7 from '../assets/bild7.jpg';
import bild8 from '../assets/bild8.jpg';
import bild9 from '../assets/bild9.jpg';
import bild10 from '../assets/bild10.jpg';
import bild11 from '../assets/bild11.jpg';
import { useNavigate } from "react-router-dom";

function Ueberuns() {
    const navigate = useNavigate();

    function closeMenu() {
        document.getElementById('menu').style.visibility = 'hidden';
        document.getElementById('fabars').style.visibility = 'visible';
        document.getElementById('fabars2').style.visibility = 'visible';
    }

    function navigate0() {
        navigate("/");
    }

    function navigate1() {
        navigate("/ueber-uns");
    }

    function navigate2() {
        navigate("/referenzen");
    }

    function navigate3() {
        navigate("/kontakt");
    }

    return (
        <div className='App'>
            <div id='menu' className='menu'>
                <div className='menu-esc-container'>
                    <div className='menu-esc' onClick={closeMenu}>
                        <IoMdClose size={'6vh'}/>
                    </div>
                </div>
                <div className='menu-items-container'>
                    <div className='menu-item' onClick={navigate0} >Home</div>
                    <div className='menu-item' onClick={navigate1} >Über uns</div>
                    <div className='menu-item' onClick={navigate2} >Referenzen</div>
                    <div className='menu-item' onClick={navigate3} >Kontakt</div>
                </div>
            </div>
            <NavBar />
            <div className='header'>
                <img className='image' src={überuns} alt=''></img>
            </div>
            <div className='body'>
                <div className='title-block'>
                    Über uns
                </div>
                <div className='überuns-text'>
                Neugierig, voller Freude, verspielt, musikalisch, etwas tollpatschig, auch mal wütend oder traurig – so sind wir - Ambrella und Schubi-Dubi.<br></br><br></br>
                Ja, das Clownspiel wurde zu unserer grossen Leidenschaft!<br></br><br></br>
                Unsere Ausbildung zum Bühnenclown absolvierten wir von 2020-2022 in Basel an der Theater- und Clownschule Yve Stöcklin.<br></br><br></br>
                Die Weiterbildung zum Besuchs-Clown besuchten wir im Jahr 2022 an der Antiheldenakademie in Augsburg.<br></br><br></br>
                Seit 2024 treffen wir uns 1x monatlich zum Clown-Training mit anderen Clowns.<br></br><br></br>
                Unser Glaube an Jesus Christus inspiriert uns und natürlich bringen auch unsere drei Kinder uns immer mal wieder auf lustige Ideen 😊
                </div>
                <div className='gallery-block-container'>
                    <div className='gallery-block'>
                        <div className='gallery-img'>
                            <img className='image' src={bild1} alt=''></img>
                        </div>
                        <div className='gallery-img'>
                            <img className='image' src={bild2} alt=''></img>
                        </div>
                        <div className='gallery-img'>
                            <img className='image' src={bild3} alt=''></img>
                        </div>
                        <div className='gallery-img'>
                            <img className='image' src={bild4} alt=''></img>
                        </div>
                        <div className='gallery-img'>
                            <img className='image' src={bild6} alt=''></img>
                        </div>
                        <div className='gallery-img'>
                            <img className='image' src={bild7} alt=''></img>
                        </div>
                        <div className='gallery-img'>
                            <img className='image' src={bild8} alt=''></img>
                        </div>
                        <div className='gallery-img'>
                            <img className='image' src={bild9} alt=''></img>
                        </div>
                        <div className='gallery-img'>
                            <img className='image' src={bild10} alt=''></img>
                        </div>
                        <div className='gallery-img'>
                            <img className='image' src={bild11} alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer'>
                Copyright {new Date().getFullYear()} © Damaris & Lukas Steiner | Homepage & Portrait-Fotos realisiert durch Davide Fontanella
            </div>
        </div>
    );
}

export default Ueberuns;